/* eslint-disable @typescript-eslint/no-unused-vars */
import whaleImage from './assets/whales.gif';

import { useHashConnect } from './hook/HashConnectAPIProvider';
import {
  Hbar,
  HbarUnit,
  TransferTransaction,
  TokenAssociateTransaction,
  TransactionReceipt,
  Client,
  PrivateKey,
  TransactionId,
  AccountId,
  Transaction,
  TokenMintTransaction,
  TokenInfoQuery,
} from '@hashgraph/sdk';
import { HashConnect, MessageTypes } from 'hashconnect';

function App() {
  // import clearPairings function from HashConnectAPIProvider
  const {
    connect,
    walletData,
    installedExtensions,
    sendTransaction: sendTr,
    clearPairings,
  } = useHashConnect();
  const { accountIds, netWork, id, privateKey, topic } = walletData;

  const conCatAccounts = (lastAccs: string, Acc: string) => {
    return lastAccs + ' ' + Acc;
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(walletData.pairingString);
  };

  const handleClick = () => {
    if (installedExtensions) connect();
    else
      alert(
        'Please install hashconnect wallet extension first. from chrome web store.'
      );
  };

  const data = {
    transfer: {
      include_hbar: true,
      to_hbar_amount: 333,
      from_hbar_amount: -333,
      toAcc: '0.0.972994',
      include_token: false,
      return_transaction: false,
      include_nft: false,
    },
    tokenAssociate: {},
  };

  const sendTransaction = async () => {
    if (accountIds) {
      let trans = new TransferTransaction().setTransactionMemo(
        'Hedera Whale Club Mint'
      );
      trans
        .addHbarTransfer(
          data.transfer.toAcc,
          Hbar.from(data.transfer.to_hbar_amount, HbarUnit.Hbar)
        )
        .addHbarTransfer(
          accountIds[0],
          Hbar.from(data.transfer.from_hbar_amount, HbarUnit.Hbar)
        );
      let transBytes: Uint8Array = await makeBytes(trans, accountIds[0]);

      let res = await sendTr(
        transBytes,
        accountIds[0],
        data.transfer.return_transaction,
        true
      );

      //handle response
      let responseData: any = {
        response: res,
        receipt: null,
      };
    }

    /*
  const apiURL = 'https://amnft.herokuapp.com/api/v1/nfts';
  const jsonDATA = JSON.stringify(responseData.rec);
const serverResponse = await fetch(apiURL, { method: 'POST', body: jsonDATA });

console.log('Server Response Here');
  console.log(serverResponse);
*/
  };

  /*
  // create a function that clears all pairings
  const clearPairings = () => {
    localStorage.removeItem('hashconnectData');
    window.location.reload();
  };

  */

  const makeBytes = async (trans: Transaction, signingAcctId: string) => {
    let transId = TransactionId.generate(signingAcctId);
    trans.setTransactionId(transId);
    trans.setNodeAccountIds([new AccountId(3)]);

    await trans.freeze();

    let transBytes = trans.toBytes();

    return transBytes;
  };

  const associateToken = async () => {
    if (accountIds) {
      let trans = await new TokenAssociateTransaction();
      let tokenIds: string[] = ['0.0.1048802'];

      trans.setTokenIds(tokenIds);
      trans.setAccountId(accountIds[0]);

      let transBytes: Uint8Array = await makeBytes(trans, accountIds[0]);

      let res = await sendTr(transBytes, accountIds[0], false);
    }
  };

  const query = new TokenInfoQuery().setTokenId('0.0.1048802');

  const totalSupply = async () => {
    //return String((await query.execute(client)).totalSupply);
  };

  return (
    <>
      <div className='main-scratch'>
        <div className='row main-block'>
          <div className='col img-block'>
            <div className='main-img'>
              <img src={whaleImage} alt='Whale' />
              <h3 className='img-heading'>1/1111</h3>
              <p className='img-txt'>Hedera Whale Club</p>
            </div>
          </div>
          <div className='col txt-block'>
            <div className='input-group'>
              <div className='input-group-prepend'></div>
              <div className='input-group-prepend'></div>
            </div>
            <h3 className='heading-txt'>333 HBAR</h3>

            {!accountIds && (
              <div>
                <h3 className='heading-txt'>
                  Paring key : {walletData.pairingString.substring(0, 15)}...
                </h3>
                <button className='btn mint' onClick={handleCopy}>
                  Copy Paring String
                </button>
                <button className='btn mint' onClick={handleClick}>
                  Connect Wallet
                </button>
              </div>
            )}

            {accountIds && accountIds?.length > 0 && (
              <div>
                <h3 className='heading-txt'>
                  Please manually associate tokenID 0.0.1048802 BEFORE minting!
                </h3>
                <button onClick={sendTransaction} className='btn mint'>
                  Mint
                </button>
                <button onClick={clearPairings} className='btn mint'>
                  Disconnect
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
